import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import parse, { domToReact } from 'html-react-parser'

import { Layout, Image, SEO } from '../components'

function replace({ type, name, attribs, children }, key = undefined) {
  const error = new Error(
    "Erreur, les heures d'ouverture ne sont pas entrés sous forme de tableau, veuillez corriger le contenu en mettant l'horaire sous forme de tableau..."
  )

  if (type === 'tag') {
    switch (name) {
      case 'table':
        return <table className="w-full">{replace(...children)}</table>
      case 'tbody':
        return (
          <tbody className="w-full">
            {children.map((node, index) => replace(node, index))}
          </tbody>
        )
      case 'tr':
        return (
          <tr key={key} className="Contact__tr">
            {domToReact(children)}
          </tr>
        )
      default:
        console.error(error)
        return error
    }
  }
}

const ContactPage = ({ data }) => {
  const { certificat } = data.file
  const { title, content } = data.wordpressPost

  let timetable = (
    <table className="w-full">
      <tbody className="w-full">
        <tr className="Contact__tr">
          <td>Lundi</td>
          <td>09:00 - 17:00</td>
        </tr>
        <tr className="Contact__tr">
          <td>Mardi</td>
          <td>09:00 - 17:00</td>
        </tr>
        <tr className="Contact__tr">
          <td>Mercredi</td>
          <td>09:00 - 17:00</td>
        </tr>
        <tr className="Contact__tr">
          <td>Jeudi</td>
          <td>09:00 - 17:00</td>
        </tr>
        <tr className="Contact__tr">
          <td>Vendredi</td>
          <td>09:00 - 17:00</td>
        </tr>
        <tr className="Contact__tr">
          <td>Samedi</td>
          <td>fermé</td>
        </tr>
        <tr className="Contact__tr">
          <td>Dimanche</td>
          <td>fermé</td>
        </tr>
      </tbody>
    </table>
  )

  if (title && content) {
    timetable = parse(content, { replace })
  }

  const google = (
    <iframe
      className="w-full"
      title="Portes Services"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2541.617947158991!2d4.4068005159169035!3d50.42958949674851!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c225882af13077%3A0xa02c0738e8dd4af2!2sPortes+Services!5e0!3m2!1sen!2sbe!4v1562700987399!5m2!1sen!2sbe"
      // width="600"
      height="450"
      frameBorder="0"
      style={{ border: 0 }}
      allowFullScreen
    />
  )

  return (
    <Layout cover={google}>
      <SEO
        title="Contact"
        description="Portes Services s.a., rue Cité de Bayemont, 1, 6040 JUMET. Tél.: 071703432 Email: info@portes-services.com, horaire d'ouverture..."
      />
      <h1 className="title">Contact</h1>
      <div className="md:flex align-middle">
        <div className="max-w-screen mx-10 md:flex-1 text-center md:text-left">
          <h2 className="">Adresse</h2>
          <h3>Portes Services s.a.</h3>
          <p>rue Cité de Bayemont, 1</p>
          <p>6040 JUMET</p>
          <p>Téléphone : 071 / 70 34 32</p>
          <p>Fax : 071 / 32 27 04</p>
          <p>Email : info@portes-services.com</p>
        </div>
        <div className="flex-1">
          <h2 className="text-center">{parse(title)}</h2>
          {timetable}
        </div>
        {certificat && (
          <div className="flex-1 text-right">
            <Image fluid={certificat.fluid} />
          </div>
        )}
      </div>
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ContactPage

export const pageQuery = graphql`
  query pageContact {
    wordpressPost(slug: { eq: "heure-douverture" }) {
      content
      title
    }
    file(relativeDirectory: { eq: "" }, name: { eq: "Portes_Services" }) {
      certificat: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
